<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Sponsors
        </h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert align-v="center" variant="warning" :show="saveMessage !== ''">{{ this.saveMessage }}</b-alert>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button :disabled="saveDisabled" variant="primary" @click="doSave">
          Save
        </b-button>
      </b-col>
    </b-row>
      <b-button
        class="mb-2 mt-2 add-button"
        @click="addSponsor()"
      >
        Add
      </b-button>
      <vuetable
        ref="sponsorstable"
        :api-mode="false"
        :fields="[
          'name',
          'image',
          'actions'
        ]"
        :data="sponsors"
      >
        <!-- <template slot="active" scope="props">
          <b-form-checkbox v-model="attachments.data[props.rowIndex].active" name="active" class="content-switch" disabled></b-form-checkbox>
        </template> -->
        <template slot="actions" scope="props">
          <font-awesome-icon
            icon="edit"
            class="action-icon"
            data-toggle="tooltip" 
            data-placement="top" 
            title="Edit item"
            @click="editSponsor(props.rowIndex)"
            ref="btnEdit"
            ></font-awesome-icon>
          <font-awesome-icon
            icon="trash"
            class="ml-2 action-icon"
            data-toggle="tooltip" 
            data-placement="top" 
            title="Remove item"
            @click="deleteSponsor(props.rowData, props.rowIndex)"
            ></font-awesome-icon>
        </template>
      </vuetable>
    <SponsorDetail v-if="selectedSponsor !== null" v-model="showModal" :sponsor="selectedSponsor" :index="sponsorIndex" />
  </b-overlay>
</template>
<script>
import SponsorDetail from "@/components/admin/sponsors/SponsorDetail.vue"
import Vuetable from "vuetable-2";

export default {
  components: {
    Vuetable,
    SponsorDetail
  },
  data() {
    return {
      isLoading: false,
      selectedSponsor: null,
      showModal: false,
      sponsorIndex: null,
      saveDisabled: true,
      saveMessage: ""
    }
  },
  async created() {
    this.selectedSponsor = null
    this.showModal = ""
    await this.$store.dispatch("Sponsors/fetchAll");
    this.saveDisabled = true;
    this.saveMessage = "";
  },
  watch: {
    sponsors: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    sponsors() {
      return this.$store.state.Sponsors.all
    }
  },
  methods: {
    async doSave() {
      this.isLoading = true;
      await this.$store.dispatch("Sponsors/update", this.sponsors);
      await this.$store.dispatch("Sponsors/fetchAll");
      this.$root.$bvToast.toast("Sponsors settings saved!", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
    addSponsor() {
      this.sponsorIndex = null
      this.selectedSponsor = {
        name: "",
        image: ""
      }
      this.toggleModal()
    },
    editSponsor(index) {
      this.sponsorIndex = index
      this.selectedSponsor = this.sponsors[index]
      this.toggleModal();
    },
    async deleteSponsor(sponsor, index) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove ${sponsor.name}?`, 
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        this.sponsors.splice(index, 1)
        await this.$store.dispatch("Sponsors/update", this.sponsors);
      }
      this.isLoading = false;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>
